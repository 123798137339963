<template>
  <div
    class="h-screen flex w-full items-center justify-center"
    style="background-color: white"
  >
    <div
      :class="getDentalVersionActive ? 'bg-image-dental' : 'bg-image-kuyum'"
      class="bg-image vx-col hidden md:block lg:w-8/12 md:w-6/12 p-8 h-full"
    >
      <div class="w-full h-full flex items-center justify-center">
        <div class="w-full text-center">
          <p style="font-size: 22px" class="w-full text-white mb-4">
            {{ $t("Reset_Password_Title_1") }}
          </p>
          <p
            style="font-size: 36px; font-weight: 600"
            class="w-full text-white"
          >
            {{ $t("Reset_Password_Title_2") }}
          </p>
        </div>
      </div>
    </div>
    <div class="sm:w-full lg:w-4/12 md:w-6/12">
      <div class="flex justify-center">
        <div class="p-12">
          <div class="mb-4">
            <img
              v-if="!getDentalVersionActive"
              src="@/assets/images/pages/novafab-logo.png"
              alt=""
            />
            <img v-else src="@/assets/images/pages/dentafab-logo.png" alt="" />
          </div>

          <div>
            <vs-input
              color="dark"
              :disabled="enablePasswordsArea"
              type="password"
              :label-placeholder="$t('Password')"
              v-model="newPassword"
              class="w-full mb-6"
              :danger="newPasswordValidate"
              :danger-text="$t('Reset_Password_Title_3')"
              @click="
                newPasswordValidate = false;
                confirmNewPasswordValidate = false;
              "
            />
            <vs-input
              color="dark"
              :disabled="enablePasswordsArea"
              type="password"
              :label-placeholder="$t('Register_Confirm_Password')"
              v-model="confirmNewPassword"
              class="w-full mb-6"
              :danger="confirmNewPasswordValidate"
              :danger-text="$t('Reset_Password_Title_4')"
              @click="confirmNewPasswordValidate = false"
            />

            <vs-button @click="resetPassword" class="w-full mb-2" color="dark">
              {{ $t("Reset") }}
            </vs-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/api/api";
import API from "@/api/config";

export default {
  data() {
    return {
      newPassword: "",
      confirmNewPassword: "",
      enablePasswordsArea: true,
      newPasswordValidate: false,
      confirmNewPasswordValidate: false,
    };
  },
  computed: {
    getDentalVersionActive() {
      return this.$store.getters.getDentalVersionActive;
    },
  },
  methods: {
    validateToken() {
      Api.post(API.BASEURL + API.VALIDATEPASSTOKEN, this.handleValidateToken, {
        token: this.$route.query.token,
      });
    },
    handleValidateToken(status) {
      this.$vs.loading.close();

      if (status == 200) {
        this.enablePasswordsArea = false;
      } else if (status == 404) {
        //TODO UYARI MESAJI
        this.$vs.notify({
          title: this.$t("Warning"),
          text: this.$t("Reset_Password_Title_5"),
          color: "warning",
        });
        this.$router
          .push("/forgot_password")
          .catch((error) => console.error(error));
      }
    },
    resetPassword() {
      if (
        this.newPassword != "" &&
        this.newPassword === this.confirmNewPassword
      ) {
        this.$vs.loading({
          text: this.$t("Loading_Please_Wait"),
        });
        Api.post(API.BASEURL + API.CONFIRMPASS, this.handleResetPassword, {
          token: this.$route.query.token,
          password: this.newPassword,
        });
      } else {
        if (this.newPassword == "") {
          this.newPasswordValidate = true;
        } else if (this.newPassword != this.confirmNewPassword) {
          this.confirmNewPasswordValidate = true;
        }
      }
    },
    handleResetPassword(status, data) {
      if (status == 200) {
        this.$vs.notify({
          title: this.$t("Successful"),
          text: this.$t("Profile_Message_5"),
          color: "success",
        });
        this.$router.push("/login").catch((error) => console.error(error));
      } else if (status == 400) {
        this.$vs.loading.close();
        this.$vs.notify({
          title: this.$t("Warning"),
          text: data.data.password[0],
          color: "warning",
        });
      }
    },
  },
  created() {
    if (localStorage.getItem("token") != null) {
      Api.logout().then((response) => {
        if (response.status == 200) {
          this.$store.dispatch("logout");
          if (this.$route.query.token != null) {
            this.validateToken();
          }
        }
      });
    } else {
      if (this.$route.query.token != null) {
        this.validateToken();
      } else {
        this.$router.push("/login").catch((error) => console.error(error));
      }
    }
  },
};
</script>

<style lang="scss">
@media screen and (max-width: 992px) {
  .loginCardInput {
    border-bottom-right-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
    border-top-left-radius: 0.75rem;
  }
}

.loginCard {
  border-bottom-right-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  border-bottom-left-radius: 1.25rem;
  border-top-left-radius: 1.25rem;
  background-color: black;
}
.loginCardInput {
  border-bottom-right-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}
.bg-image-dental {
  background-image: url("/img/dental-login-bg.jpg");
  background-position: center;
  background-repeat: repeat;
}
.bg-image-kuyum {
  background-image: url("/img/kuyum-login-bg.jpg");
  background-position: center;
  background-repeat: repeat;
}
</style>
